<template>
  <section>
    <div class="container-fluid">
      <div class="d-flex align-items-center">
        <router-link to="/docs" tag="h1" :class="[isAndroid ? '' : 'font-weight-bolder']" cursor-pointer>Документи</router-link>
        <div class="mr-auto"></div>
        <router-link :to="'/docs'">
          <i class="fa fa-long-arrow-left mr-2"></i>
          <span>До списку</span> 
        </router-link>
      </div>
      <p class="fade" :class="getCode &amp;&amp; 'show'">
        {{ getName | caps }} 
        {{ getCode | code }}
      </p>
    </div>
    <div class="bg-white">
      <div class="container">
        <div class="row justify-content-center"> 
          <div class="col col-lg-8 col-xl-6">
            <div class="p-2"></div>
            <!-- Header -->
            <h2>Новий документ</h2>

            <form action="/" method="post" v-on:submit.prevent="setupDocument">
              <fieldset :disabled="loading" >
                <div class="form-group">
                  <div class="custom-file">
                    <input 
                      class="custom-file-input" 
                      type="file"
                      ref="fileInput"
                      id="customFile"
                      @change="openFile"
                      accept=".doc, .docx, .odt, .txt, .pdf, .xls, .xlsx, .jpg, .jpeg"
                    >
                    <label 
                      class="custom-file-label" 
                      for="customFile"
                      ref="filename"
                    >
                      Оберіть документ
                    </label>
                  </div>
                </div>

                <div class="form-group">
                  <input
                    v-model="subject" 
                    class="form-control"
                    spellcheck="false"
                    id="nameDocument"
                    placeholder="Назва документа"
                    required=""
                  />
                </div>

                <div class="form-group">
                  <textarea 
                    class="form-control"
                    id="message"
                    placeholder="Повідомлення"
                    v-model="message"
                    rows="4"
                    maxLength="1024"
                    required=""
                  />
                </div>
              
                <div class="form-group">
                  <div class="input-group">
                    <input
                      class="form-control subscriber"
                      spellcheck="false"
                      v-on:keydown.enter="checkNumber"
                      placeholder="Код компаніі або ІНН підпісанта"
                      id="subscribers"
                      v-model="subscribers"
                      autocomplete="off"
                      type="text"
                      @focus="toggleSuggests"
                      @blur="toggleSuggests"
                      @input="skipError"
                    >
                    <div class="input-group-append">
                      <button class="btn btn-light border" type="button" @click="checkNumber" :disabled="!checkCodeLength || loadingSignature">
                        <i class="fa fa-search" v-if="!loadingSignature"></i>
                        <i class="fas fa-circle-notch fa-spin" v-else></i>
                      </button>
                    </div>
                  </div>

                  <div class="dropdown">
                    <transition appear name="dropdown" @before-enter="beforeEnter" @after-enter="afterEnter" @before-leave="beforeLeave" @after-leave="afterLeave">
                      <div class="dropdown-menu w-100 fade overflow-auto" v-show="showSuggests && storedSignatures.length" style="height: 10rem">
                        <a class="dropdown-item text-truncate" href="#" v-for="signature, idx in storedSignatures" :key="idx" @click.prevent="chooseSuggest(idx)">
                          <span class="text-monospace">{{ signature.code }}</span>
                          &nbsp;{{ signature.name }}
                        </a>
                      </div>
                    </transition>
                  </div>
                  <div class="invalid-feedback" :class="{'d-block' : incorrectCode}">Невірний код компанії або ІПН</div>
                </div>
                
                <transition-group class="signatures" name="notifications">
                  <div class="form-group" v-for="(attachment, index) in attachments" :key="attachment.code">
                    <a class="text-danger" href="#" title="Видалити підпис" @click.prevent="removeAttachment(index)">
                      <i class="far fa-fw mr-1 fa-minus-circle"></i>
                    </a>
                    <span v-if="attachment.code.length === 10">
                      <span class="text-monospace">{{ attachment.code }}</span>
                    </span>
                    <a
                      class="text-monospace"
                      v-else
                      :href="`https://opendatabot.ua/c/${attachment.code}`"
                      target="_blank"
                      title="Відкрити дані в новому вікні"
                    >{{ attachment.code }}</a>
                    &nbsp;{{ attachment.name }}
                  </div>
                </transition-group>
                
                <div class="p-2"></div>
                
                <transition appear name="expand" @enter="startExpand" @after-enter="afterExpand" @before-leave="endExpand">
                  <section v-show="ownSignature">
                    <div class="form-group">
                      <i class="far fa-fw mr-1 fa-signature"></i>
                      <span class="text-monospace" >{{ getCode }}</span>
                      &nbsp;{{ getName }}
                    </div>
                  </section>
                </transition>

                <div class="form-group">
                  <div class="custom-control custom-switch">
                    <input class="custom-control-input" type="checkbox" id="notify_by_email" v-model="ownSignature">
                    <label class="custom-control-label" for="notify_by_email">Поставити власний підпис</label>
                  </div>
                </div>

                <div class="form-group custom-control custom-switch">
                  <input class="custom-control-input" type="checkbox" id="public_switch" v-model="publicSwitch">
                  <label class="custom-control-label" for="public_switch">Публічний</label>
                </div>
              </fieldset>
            
              <div class="form-group">
                <button class="btn btn-primary" :disabled="loading" type="submit" >
                  <span>
                    <i class="far fa-fw mr-1" :class="[loading ? 'fa-spin fa-circle-notch' : 'fa-file-plus']"/>
                    Створити
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      ownSignature: false,
      publicSwitch: false,
      subscribers: '',
      subject: '',
      message: '',
      attachments: [],
      file: null,
      loading: false,
      incorrectCode: false,
      loadingSignature: false,
      storedSignatures: [],
      showSuggests: false,
      existedSignatureIdx: NaN,
    }
  },
  computed: {
    ...mapGetters('app', ['isAndroid','getCode', 'getName',]),
    ...mapGetters('app', ['isAuthorized']),
    checkCodeLength() {
      let length = this.subscribers.length;
      return length == 8 || length == 10 ? true : false;
    }
  },
  methods: {
    ...mapActions('docs', ['createDocument', 'getSubscribers']),
    afterEnter(e) {
      e.classList.toggle("show");
    },
    beforeEnter(e) {
      e.classList.toggle("d-block");
    },
    beforeLeave(e) {
      e.classList.toggle("show");
    },
    afterLeave(e) {
      e.classList.toggle("d-block");
    },
    afterExpand(el) {
      el.style.height = `auto`;
    },
    startExpand(el) {
      const { width } = getComputedStyle(el);
      el.style.width = width;
      el.style.position = `absolute`;
      el.style.visibility = `hidden`;
      el.style.height = `auto`;

      const { height } = getComputedStyle(el);

      el.style.width = null;
      el.style.position = null;
      el.style.visibility = null;
      el.style.height = 0;

      getComputedStyle(el).height;
      setTimeout(() => {
        el.style.height = height;
      });
    },
    endExpand(el) {
      const { height } = getComputedStyle(el);

      el.style.height = height;

      getComputedStyle(el).height;
      setTimeout(() => {
        el.style.height = 0;
      });
    },
    skipError() {
      this.incorrectCode = false;
    },
    async openFile () {
      if (!this.$refs.fileInput.files.length) return;
      
      const file = this.$refs.fileInput.files[0];
      let reader = new FileReader();

      reader.onload = function (event) {
        file.base = event.target.result
      }
      
      reader.readAsDataURL(file);

      this.$emit('input', file)

      this.file = file
      this.subject = file.name
      this.$refs.filename.innerText = file.name
    },
    clearFile () {
      this.file = null
      this.$emit('input', null)
    },
    removeAttachment (index) {
      this.attachments.splice(index, 1);
    },
    async checkNumber (e) {
      if (!this.subscribers) {
        e.preventDefault();
        return
      }
      this.loadingSignature = true;
      const response = await this.getSubscribers(this.subscribers);
      if (response && response.status === 200) {
        const { data } = response;
        if (!data.error && !this.attachments.find(item => item.code === data.data.code)) {
          this.attachments.push(data.data);
          this.addSuggest({ name: data.data.name, code: data.data.code });
        }
        this.subscribers = '';
      } else {
        this.incorrectCode = true;
      }
      this.loadingSignature = false;
    },
    async setupDocument () {
      if ((!this.attachments.length && !this.ownSignature) || !this.subject || !this.message || !this.file) 
        return
      
      this.loading = true;
      const response = await this.createDocument({
        subject: this.subject,
        message: this.message,
        publicSwitch: this.publicSwitch,
        attachment: {
          name: this.file.name,
          data: this.file.base,
          actions: [
            ...this.attachments.map(attachment => ({
              type: 'sign',
              code: attachment.code,
              handler: ''
            })),
            ...this.ownSignature ? [
              { type: 'sign', code: this.getCode, handler: '' }
            ] : [],
          ]
        }
      })

      if (response && response.status >= 200 && response.status < 400) {
        const { data } = response;
        if (!data.error ) {
          this.$router.push('/docs').catch(e => e)
        }
      }
      this.loading = false;
    },
    addSuggest(item) {
      if (!item) {
        return;
      }
      let findWithAttr = (array, attr, value) => {
        for (var i = 0; i < array.length; i += 1) {
          if (array[i][attr] === value) {
            return i;
          }
        }
        return -1;
      };
      let ifExistIdx = findWithAttr(this.storedSignatures, "code", item.code);
      if (ifExistIdx > -1) {
        this.$delete(this.storedSignatures, ifExistIdx);
      }

      this.storedSignatures.unshift(item);
      this.saveSuggest();
    },
    removeSuggest(x) {
      this.storedSignatures.splice(x, 1);
      this.saveSuggest();
    },
    saveSuggest() {
      const parsed = JSON.stringify(this.storedSignatures);
      localStorage.setItem("storedSignatures", parsed);
    },
    toggleSuggests() {
      this.showSuggests = !this.showSuggests;
    },
    chooseSuggest(idx) {
      this.subscribers = this.storedSignatures[idx].code;
      this.checkNumber();
    },
  },
  mounted() {
    if (!this.isAuthorized) this.$router.push('/').catch(e => e)
    if (localStorage.getItem("storedSignatures")) {
      try {
        this.storedSignatures = JSON.parse(
          localStorage.getItem("storedSignatures")
        );
      } catch (e) {
        localStorage.removeItem("storedSignatures");
      }
    }
  },
}
</script>